import React from 'react';
import { graphql } from 'gatsby';
import GridContainer from '../layout/Grid/GridContainer';
import GridRow from '../layout/Grid/GridRow';
import GridColumn from '../layout/Grid/GridColumn';
import ProjectTeaser from '../components/ProjectTeaser/ProjectTeaser';
import Section from '../layout/Section.js';
import IntroSection from '../components/IntroSection';
import Hero from '../components/Hero';
import SEO from '../components/SEO';
import CompactCTA from '../components/CompactCta';

export default function ProjektePage({ data }) {
  return (
    <>
      <SEO title={data.page.seoTitle} description={data.page.seoDescription} />

      <Hero hero={data.page} />
      <Section>
        <GridContainer>
          <GridRow>
            <GridColumn start="3" span="8">
              <IntroSection intro={data.page} />
            </GridColumn>
          </GridRow>
        </GridContainer>
      </Section>

      <Section color="subtle" id="projekte">
        <GridContainer>
          <GridRow>
            <GridColumn start="3" span="9">
              <h2>Projekt-Auswahl</h2>
              <ProjectTeaser projects={data.projects.nodes}></ProjectTeaser>
            </GridColumn>
          </GridRow>
        </GridContainer>
      </Section>

      <Section color="subtle05">
        <GridContainer>
          <GridRow>
            <GridColumn start="3" span="9">
              <CompactCTA />
            </GridColumn>
          </GridRow>
        </GridContainer>
      </Section>
    </>
  );
}

export const query = graphql`
  query ProjectQuery {
    page: sanityMainPages(slug: { current: { eq: "projekte" } }) {
      headline
      seoTitle
      seoDescription
      _rawIntroCopy
      heroHeadline
      enableHero
      heroImage {
        alt
        asset {
          fluid(maxWidth: 1920) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }

    projects: allSanityProject(sort: { fields: [projectDate], order: DESC }) {
      nodes {
        customerName
        id
        headline
        achievements
        slug {
          current
        }
        photos {
          photo {
            asset {
              fixed(width: 400, height: 400) {
                ...GatsbySanityImageFixed
              }
              fluid(maxWidth: 400, maxHeight: 400) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  }
`;
