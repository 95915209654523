import React from 'react';
import Img from 'gatsby-image';
import EqGrid from '../../layout/Grid/EqGrid';
import { Link } from 'gatsby';
import { StyledTeaser } from './ProjectTeaserStyles.js';
import Button from '../../components/Button';

// Single Teaser
function SingleTeaser({ project }) {
  const projectImage = project.photos[0];
  return (
    <Link
      className="link--wrapper"
      style={{ width: '100%' }}
      to={`/projekte/${project.slug.current}`}
    >
      <StyledTeaser>
        <div className="project-teaser__image-wrapper">
          <Img
            className="project-teaser__photo"
            // https://www.gatsbyjs.com/tutorial/gatsby-image-tutorial/#aspect-ratio
            fluid={{ ...projectImage.photo.asset.fluid, aspectRatio: 1 / 1 }}
            // style={maxWidth: "100%"}
            durationFadeIn={400}
            alt=""
            style={
              {
                // height: "100%",
                // maxWidth: "85%",
              }
            }
            imgStyle={
              {
                // width: "100%",
                // objectFit: "cover",
              }
            }
          />
          {/* {<img className="project-teaser__logo" src={project.logo} />} */}
        </div>
        <div className="project-teaser__content-wrapper">
          <h3 className="project-teaser__headline">{project.customerName}</h3>
          <ul className="project-teaser__list">
            {project.achievements.map(bullet => (
              <li key={bullet}>{bullet}</li>
            ))}
          </ul>
        </div>
      </StyledTeaser>
    </Link>
  );
}

// Loopin' over it
export default function ProjectTeaser({ projects, showMoreButton, limit }) {
  return (
    <>
      <EqGrid sm="2" md="3">
        {projects.slice(0, limit).map(project => (
          <SingleTeaser key={project.id} project={project} />
        ))}
      </EqGrid>
      {showMoreButton && (
        <EqGrid md="1" smallCentered={true} centered={true}>
          <Button to="/projekte/">Zur Projektübersicht</Button>
        </EqGrid>
      )}
    </>
  );
}
