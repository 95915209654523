import React from 'react';
import Img from 'gatsby-image';
import { above } from '../styles/MediaQueries';
import styled from 'styled-components';
import heroImg from '../assets/header_img_1920x480px.jpg';
import halfCircle from '../assets/half-circle--beige.svg';

const StyledHero = styled.section`
  z-index: 0;
  margin: 0 auto;
  /* background-image: url(${heroImg});
    background-size: cover; */
  /* max-width: 1920px; */
  height: 300px;
  /* display: flex;
    align-items: flex-end;z
    justify-content: flex-end; */
  /* padding-right: 4rem; */
  /* padding-bottom: 2rem; */
  position: relative;
  overflow: visible;
  text-align: right;
  ${above.medium`
		height: 480px;
		text-align: left;
    `}

  .gatsby-image-wrapper {
    width: 100%;
    position: relative;
    &:before {
      position: absolute;
      background-image: linear-gradient(
        155deg,
        rgba(0, 0, 0, 0) 55%,
        rgba(0, 0, 0, 0.8)
      );
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      content: '';
      z-index: 1;
      pointer-events: none;
    }
  }

  .circle {
    /* content: ""; */
    position: absolute;
    background-image: url(${halfCircle});
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 120%;
    top: 0;
    left: 0;
    opacity: 0.3;
    ${above.medium`
     	opacity: .5;
			height: 140%;
    `}
    pointer-events: none;
    z-index: 1;
  }

  .hero__headline {
    color: white;
    font-weight: 600;
    font-size: var(--font-2xl);
    margin-bottom: 0;
    letter-spacing: 2.63px;
    position: absolute;
    bottom: 2rem;
    right: 0.5rem;
    text-transform: uppercase;
    letter-spacing: 2.63px;
    hyphens: auto;
    word-break: break-word;
    ${above.medium`
			right: calc(2rem + 2vw);
		`}
    z-index: 2;
  }

  ${above.medium`
        color: green;
    `}
  ${above.large`
        color: hotpink;
    `}
`;

export default function Hero({ hero }) {
  // early return when not enabled or image field empty
  const showHero = hero.enableHero;
  if (!showHero || !hero.heroImage) return null;
  return (
    <>
      <StyledHero>
        <Img
          // className="project-teaser__photo"
          fluid={hero.heroImage.asset.fluid}
          durationFadeIn={400}
          alt={hero.heroImage.alt}
          // TODO: Check benefits and usage
          // height="480px"
          // width="1920px"
          style={{
            // width: "100%",
            height: '100%',
          }}
          imgStyle={{
            objectFit: 'cover',
            objectPosition: '25% 50%',
            // width: "100%",
          }}
          loading="eager"
        />
        <div className="circle"></div>
        {hero.heroHeadline && (
          <h1 className="hero__headline">
            {hero.heroHeadline.map((headlineLine, i) => (
              <span key={i}>
                {headlineLine}
                {i < hero.heroHeadline.length - 1 ? <br /> : ''}
              </span>
            ))}
          </h1>
        )}
      </StyledHero>
    </>
  );
}
