import React from 'react';
import styled, { css } from 'styled-components';
import Button from '../components/Button';

const StyledCompactCta = styled.div``;

export default function CompactCTA() {
  return (
    <StyledCompactCta>
      <h3 className="mt-0">
        Sie fragen sich, wer hinter diesen Projekten steht?
      </h3>
      <p>Erfahren Sie mehr über unser Team und unsere Partner.</p>
      <Button to="/wir/" className="secondary">
        Das sind wir
      </Button>
    </StyledCompactCta>
  );
}
