import styled from 'styled-components';
import { below } from '../../styles/MediaQueries';

export const StyledTeaser = styled.div`
  a {
    border-bottom: none;
    font-weight: 400;
  }

  display: flex;
  flex-direction: column;
  align-items: center;

  ${below.small`
    padding: 0 2rem;
  `}

  .project-teaser__content-wrapper {
    padding: 1rem;
  }

  .project-teaser__headline {
    color: var(--brand);
    text-decoration: none;
    margin-top: 0.5rem;
    /* overflow-wrap: break-word;
    word-break: break-word;
    hyphens: auto !important; */
  }

  .project-teaser__list {
    padding-left: 1em;
    font-family: var(--sans-serif);
    font-size: var(--font-button);
    line-height: 1.2;
  }

  .project-teaser__image-wrapper {
    width: 85%;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    border-radius: 50%;

    .project-teaser__photo {
      border-radius: 50%;
      z-index: -1;
      /* filter: grayscale(100%); */
      /* opacity: 0.66; */
      /* transition: all 0.1s ease-in; */
    }

    .project-teaser__logo {
      position: absolute;
      top: 0;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 0;
      width: 66%;
      /* transition: all 0.1s ease-in; */
    }
  }

  &:hover {
    .project-teaser__photo {
      opacity: 0.8;
      /* filter: grayscale(0%); */
      /* transform: scale(1.05); */
    }

    .project-teaser__logo {
      top: 33%;
      opacity: 0;
      transform-origin: center center;
    }

    .project-teaser__headline,
    li {
      opacity: 0.8;
    }
  }
`;
