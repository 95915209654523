import React from 'react';
import styled from 'styled-components';
import BlockContent from '@sanity/block-content-to-react';

const StyledIntroSection = styled.div`
  h2 {
    /* font-weight: 600;
    font-size: var(--font-base);
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin-bottom: 4rem; */
  }
`;

export default function IntroSection({ intro }) {
  return (
    <StyledIntroSection>
      <h2>{intro.headline}</h2>
      {/* <h2>{intro.subline}</h2> */}
      <BlockContent blocks={intro._rawIntroCopy} />
    </StyledIntroSection>
  );
}
